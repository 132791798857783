<template>
  <div v-loading="loaders.resources" class="m-0 px-3 pt-4 pb-0">
    <add-support-group-category-modal @updateSupportGroupCategories="updateSupportGroupCategories">
    </add-support-group-category-modal>
    <edit-support-group-category-modal @updateSupportGroupCategories="updateSupportGroupCategories" :id="editId">
    </edit-support-group-category-modal>
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="d-flex justify-content-between">
      <h1 class="m-0">List of Support Group Categories</h1>
      <div class="d-flex justify-content-end align-items-center">
        <el-button @click="toggleAddSupportCategoryModal" class="button-hover-effect btn-secondary-action title-btn-padding button-font">Add New</el-button>
      </div>
    </div>

    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.resources"
        >Delete
        </base-button>
        <base-button class="mt-0 ml-2 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.resources"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 ml-2 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.resources"
        >Deactivate
        </base-button>
      </div>
      <el-table
        class="table-responsive table-flush checklist-items-table checklist-table"
        header-row-class-name="thead-light"
        width="100%"
        ref="multipleSelectionTable"
        :data="response.supportGroupCategories.data"
        @selection-change="supportGroupMultiSelect">
        <el-table-column width="80px" prop="selected" type="selection">
        </el-table-column>
        <el-table-column min-width="300px" label="CATEGORY NAME" prop="supportGroupCategoryName">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              <span>{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="status">
          <template slot-scope="scope">
                <span :class="['status',getStatus(scope.row.status)]">
                  <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                  <span>{{ getStatus(scope.row.status) }}</span>
                </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img class="pointer mr-2" @click="toggleEditSupportCategoryModal(row.id)" src="/img/icons/buttons/edit.svg" alt="">
            <img
              class="pointer"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.supportGroupCategories.total"
          v-model="response.supportGroupCategories.current_page"
          :per-page="parseInt(response.supportGroupCategories.per_page)"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>

    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeSupportGroup"
        @onRemoveMultiple="deleteSupportGroup"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import BackButton from "@/components/Router/BackButton"
import AddSupportGroupCategoryModal
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupCategory/AddSupportGroupCategoryModal"
import EditSupportGroupCategoryModal
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupCategory/EditSupportGroupCategoryModal"

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")
export default {
  name: "SupportGroupCategory",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton,
    AddSupportGroupCategoryModal,
    EditSupportGroupCategoryModal
  },
  data() {
    return {
      selectedRows: [],
      loaders: {
        resources: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      response: {
        supportGroupCategories: {
          data: [],
          current_page:1,
          per_page:10,
          total:0,
        }
      },
      params: {
        page: 1,
        paginate: 5,
      },
      editId: ''
    }
  },
  created() {
    this.fetchSupportGroupCategories()
  },
  methods: {
    /**
     * Update list by fetching
     */
    updateSupportGroupCategories() {
      this.fetchSupportGroupCategories()
    },

    /**
     * Show Add support group category modal
     */
    toggleAddSupportCategoryModal() {
      this.$store.commit('showAddSupportGroupCategoryModal')
    },

    /**
     * Show Edit support group category modal
     */
    toggleEditSupportCategoryModal(id) {
      this.editId = id
      this.$store.commit('showEditSupportGroupCategoryModal')
    },

    /**
     * Fetch All Support Groups Categories and save it in response data.
     */
    fetchSupportGroupCategories() {
      let vm = this
      vm.loaders.resources = true
      const config = {
        params: this.params,
      }
      vm.$store.dispatch('_fetchSupportGroupCategories', config)
      .then(response => {
        vm.response.supportGroupCategories = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.resources = false
      })
    },

    /**
     * Change and load the requested page
     * @param {number} page - Page number
     */
    changePage(page){
      this.params.page = page
      this.fetchSupportGroupCategories()
    },

    /**
     * Handle the Delete Request
     * @param index - Index from the table
     * @param row - Row from the table on which event is occurred
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Get Status in number and returns its Value in String
     * @param {number} status - Status of the Support Group
     * @returns {string}
     */
    getStatus(status) {
      return (status === 0) ? 'Inactive' : 'Active'
    },

    /**
     * Handles the Status Activate request
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handles the Status Deactivate request
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Set new status value
     * @param {number} ids - Selected ids from the table
     * @param {boolean} status - Status of the selected ids
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.resources = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }
      vm.$store.dispatch('_updateStatusSupportGroupCategories', payload)
      .then(response => {
        vm.$notify.success({
          title: 'Support Group Category',
          message: 'Status Updated Successfully.'
        })
        vm.fetchSupportGroupCategories()
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.resources = false
      })
    },

    /**
     * Set value of selected rows
     * @param {number[]} selectedRows - Ids of selected rows from table
     */
    supportGroupMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Prompt the Remove Modal and set the remove id
     * @param row - Selected row from the table
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Support Group Category.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove Modal and set the remove ids
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Support Group Categories.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handles the delete request of specific Support Group
     * @param {number} id - Id of the relevant support group
     */
    removeSupportGroup(id) {
      this.deleteRequest(id)
    },

    /**
     * Handles the delete request for Support Group
     */
    deleteSupportGroup() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete the Support group(s) for selected ids
     * @param {*[]} data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.resources = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('_deleteSupportGroupCategory', payload)
      .then(response => {
        vm.$store.commit("hideRemoveModal")
        vm.removeId = []
        vm.$notify.success({
          title: 'Support Group Category',
          message: 'Support Group Category Deleted Successfully.'
        })
        vm.fetchSupportGroupCategories()
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.checkboxButton.Delete = false
        vm.loaders.resources = false
      })
    },
  },
  computed: {
    /**
     * Extract the selected ids from the list of support group
     * @returns {number[]} selectedIds - Selected Ids from table
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>

.indicator {
  height: 7px;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
