<template>
  <modal v-loading="loaders.resource" :show.sync="$store.state.modals.editSupportGroupCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Edit Support Group Category</h1>
    <support-group-category-form
      :support-group-category-data-to-edit="response.supportGroupCategory"
      @updateSupportGroupCategory="updateSupportGroupCategory">
    </support-group-category-form>
  </modal>
</template>

<script>
import SupportGroupCategoryForm
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupCategory/SupportGroupCategoryForm"

export default {
  name: "EditSupportGroupCategoryModal",
  props: ['id'],
  components: {
    SupportGroupCategoryForm
  },
  data() {
    return {
      editCategoryId: '',
      response: {
        supportGroupCategory: ''
      },
      loaders: {
        resource: false
      }
    }
  },
  watch: {
    /**
     * Watcher to check newVal of Id prop
     * @param {number} newVal - New Value of the Id prop
     */
    id: function (newVal) {
      if (newVal != null) {
        this.editCategoryId = newVal
        this.getSupportGroupCategory()
      }
    }
  },
  methods: {
    /**
     * Fetch the relevant support group category
     */
    getSupportGroupCategory() {
      let vm = this
      vm.loaders.resource = true
      const payload = { id: vm.editCategoryId }
      vm.$store.dispatch('_getSupportGroupCategory', payload)
      .then(response => {
        vm.response.supportGroupCategory = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.resource = false
      })
    },

    /**
     * Update the support group category
     * @param {event} event - Event object
     */
    updateSupportGroupCategory(event) {
      let vm = this
      vm.loaders.resource =true
      const formData = {}
      Object.assign(formData,event)
      formData.id = vm.editCategoryId
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      vm.$store.dispatch('_updateSupportGroupCategory', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group Category updated successfully.'
        })
        vm.$store.commit('hideEditSupportGroupCategoryModal')
        vm.$emit('updateSupportGroupCategories')
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.resource = false
      })
    }
  }
}
</script>

<style scoped>

</style>
