<template>
  <modal v-loading="loaders.supportGroupCategory" :show.sync="$store.state.modals.addSupportGroupCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Add Support Group Category</h1>
    <support-group-category-form
      @storeSupportGroupCategory="storeSupportGroupCategory"
      ref="supportGroupCategoryForm">
    </support-group-category-form>
  </modal>
</template>

<script>

import SupportGroupCategoryForm
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupCategory/SupportGroupCategoryForm"

export default {
  name: "AddSupportGroupCategoryModal",
  components: {
    SupportGroupCategoryForm
  },
  data() {
    return {
      loaders: {
        supportGroupCategory: false
      }
    }
  },
  methods: {
    /**
     * Store a new support group category
     * @param {event} event - Event object
     */
    storeSupportGroupCategory(event) {
      let vm = this
      vm.loaders.supportGroupCategory =true
      const formData = {}
      Object.assign(formData,event)
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      vm.$store.dispatch('_storeSupportGroupCategory', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group Category created successfully.'
        })
        this.$refs.supportGroupCategoryForm.resetForm()
        vm.$store.commit('hideAddSupportGroupCategoryModal')
        vm.$emit('updateSupportGroupCategories')
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Add Support Group Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.supportGroupCategory = false
      })
    }
  }
}
</script>

<style scoped>

</style>
