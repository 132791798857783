<template>
  <div>
    <validation-observer ref="supportGroupCategoryForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          type="text"
          :rules="{required: true}"
          name="name"
          v-model="request.supportGroupCategoryForm.name"
          label="Category Name*"
          placeholder="Name">
        </base-input>
        <base-input name="status" label="Status*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Status*"
            v-model="request.supportGroupCategoryForm.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>
        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="hideForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "SupportGroupCategoryForm",
  props: ['supportGroupCategoryDataToEdit'],
  data() {
    return {
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ]
      },
      request: {
        supportGroupCategoryForm: {
          name: '',
          status: ''
        }
      }
    }
  },
  watch: {
    /**
     * Save new value to supportGroupCategoryDataToEdit property
     * @param newVal - New Value of supportGroupCategoryDataToEdit property
     */
    supportGroupCategoryDataToEdit: function (newVal) {
      this.request.supportGroupCategoryForm.name = newVal.name ?? ''
      this.request.supportGroupCategoryForm.status = newVal.status ?? ''
    }
  },
  methods: {
    /**
     * Validates the form field, submit it and emit relevant function
     */
    async submitForm() {
      let vm = this
      const isSupportGroupCategoryFormValid = await this.$refs['supportGroupCategoryForm'].validate();
      if (isSupportGroupCategoryFormValid) {
        if(this.$store.state.modals.addSupportGroupCategory) {
          vm.$emit('storeSupportGroupCategory', vm.request.supportGroupCategoryForm)
        } else {
          vm.$emit('updateSupportGroupCategory', vm.request.supportGroupCategoryForm)
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Hide the form
     */
    hideForm() {
      if(this.$store.state.modals.addSupportGroupCategory) {
        this.resetForm()
        this.$store.commit('hideAddSupportGroupCategoryModal')
      } else {
        this.$store.commit('hideEditSupportGroupCategoryModal')
      }
    },

    /**
     * Reset the Support group category form fields
     */
    resetForm(){
      this.request.supportGroupCategoryForm.name = ''
      this.request.supportGroupCategoryForm.status = ''
      this.$refs.supportGroupCategoryForm.reset();
    }
  }
}
</script>

<style scoped>

</style>
